<template>
  <div class="view-rep-modal-contents mt-3">
    <h5><strong>Send Representative Contract</strong></h5>
    <div class="addMessage-form mt-3">
      <select
        class="select-input mb-3"
        v-model="selectedMainOption"
        @change="toggleChange(selectedMainOption)"
      >
        <option
          v-for="option in filteredOptions"
          :key="option"
          :value="option"
          >{{ formatOption(option) }}</option
        >
      </select>
    </div>
    <!-- Non Category Contract -->

    <div class="row" v-if="selectedMainOption === 'nonCategoryContract'">
      <SelectInput
        name="checkedLevelABex"
        :count="
          contractAgentLevels.Abex - 1 < 0 ? 0 : contractAgentLevels.Abex - 1
        "
        title="BEX Ancillary Contract"
        @level-selected="handleLevelSelected"
        :validateInput="false"
      />

      <SelectInput
        name="checkedLevelPBex"
        title="BEX Premier Contract"
        @level-selected="handleLevelSelected"
        :count="
          contractAgentLevels.Pbex - 1 < 0 ? 0 : contractAgentLevels.Pbex - 1
        "
        :validateInput="false"
      />

      <SelectInput
        title="Smart - Ancilliary Contract"
        name="checkedLevelAlc"
        @level-selected="handleLevelSelected"
        :count="contractAgentLevels.alc"
        :validateInput="false"
      />

      <SelectInput
        name="checkedLevelPec"
        :count="contractAgentLevels.pec"
        title="EWA Contract (Union Only)"
        @level-selected="handleLevelSelected"
        :validateInput="false"
      />

      <SelectInput
        name="checkedLevelFAnci"
        :count="contractAgentLevels.fegliAncillary"
        title="GPBP (FEGLI) Contract"
        @level-selected="handleLevelSelected"
        :validateInput="false"
      />

      <SelectInput
        name="checkedLevelPAnci"
        :count="
          checkForPatriotAncillaryLevel(
            contractAgentLevels.patriotAncillary,
            contractAgentLevels.patriotPremier
          )
        "
        title="Patriot - Ancillary Contract"
        @level-selected="handleLevelSelected"
        :validateInput="false"
      />

      <SelectInput
        name="checkedLevelPPre"
        :count="contractAgentLevels.patriotPremier"
        title="Patriot - Ancillary Contract"
        @level-selected="handleLevelSelected"
        style="visibility: hidden"
        :validateInput="false"
      />
    </div>
    <!-- Category Contracts -->
    <ValidationObserver ref="validationObserver">
      <div class="row" v-if="selectedMainOption === 'categoryContract'">
        <SelectInput
          name="checkedLevelMedical"
          :count="contractAgentLevels.med"
          title="Medical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelDental"
          :count="contractAgentLevels.dent"
          title="Dental"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelVision"
          :count="contractAgentLevels.vision"
          title="Vision"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelTLife"
          :count="contractAgentLevels.term_life"
          title="Term Life"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelBundles"
          :count="contractAgentLevels.bundled"
          title="Bundles"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelLMedical"
          :count="contractAgentLevels.lim_med"
          title="Limited Medical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelAccident"
          :count="contractAgentLevels.accident"
          title="Accident"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelCritical"
          :count="contractAgentLevels.critical"
          title="Critical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelHospital"
          :count="contractAgentLevels.hospital"
          title="Hospital"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelLStyle"
          :count="contractAgentLevels.life_style"
          title="Life Style"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelPet"
          :count="contractAgentLevels.pet"
          title="Pet"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelGIssue"
          :count="contractAgentLevels.guarn_issue"
          title="Guranteed Issue"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelTMedicine"
          :count="contractAgentLevels.tele_med"
          title="Telemedicine"
          @level-selected="handleLevelSelected"
        />
      </div>
      <!-- Premier Contract -->
      <div class="row" v-if="selectedMainOption === 'premierContract'">
        <SelectInput
          name="checkedLevelMedicalL7"
          :count="contractAgentLevels.medL7"
          title="Medical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelDentalL7"
          :count="contractAgentLevels.dentL7"
          title="Dental"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelVisionL7"
          :count="contractAgentLevels.visionL7"
          title="Vision"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelTLifeL7"
          :count="contractAgentLevels.term_lifeL7"
          title="Term Life"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelBundlesL7"
          :count="contractAgentLevels.bundledL7"
          title="Bundles"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelLMedicalL7"
          :count="contractAgentLevels.lim_medL7"
          title="Limited Medical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelAccidentL7"
          :count="contractAgentLevels.accidentL7"
          title="Accident"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelCriticalL7"
          :count="contractAgentLevels.criticalL7"
          title="Critical"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelHospitalL7"
          :count="contractAgentLevels.hospitalL7"
          title="Hospital"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelLStyleL7"
          :count="contractAgentLevels.life_styleL7"
          title="Life Style"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelPetL7"
          :count="contractAgentLevels.petL7"
          title="Pet"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelGIssueL7"
          :count="contractAgentLevels.guarn_issueL7"
          title="Guaranteed Issue"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelTMedicineL7"
          :count="contractAgentLevels.tele_medL7"
          title="Telemedicine"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelSolsticeDental7"
          :count="contractAgentLevels.dentSolsticeL7"
          title="Dental-Solstice"
          @level-selected="handleLevelSelected"
        />
        <SelectInput
          name="checkedLevelSolsticeVision7"
          :count="contractAgentLevels.visionSolsticeL7"
          title="Vision-Solstice"
          @level-selected="handleLevelSelected"
        />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import SelectInput from "./partials/SelectInput.vue";
import { ValidationObserver } from "vee-validate";
import Api from "@/resource/AdminApi";
import axios from "axios";
import { mapGetters } from "vuex";
import { action_origin } from "../../utils/constant";
export default {
  name: "SelectContractRegistration",
  components: {
    SelectInput,
    ValidationObserver,
  },
  data() {
    return {
      contractLevels: {},
      contractAgentLevels: {},
      selectedMainOption: "nonCategoryContract",
      optionArrays: [
        "nonCategoryContract",
        "categoryContract",
        "premierContract",
      ],
      contractId: null,
    };
  },

  methods: {
    checkForPatriotAncillaryLevel(patriotAncillary, patriotPremier = 0) {
      return patriotAncillary > patriotPremier
        ? patriotAncillary
        : patriotPremier;
    },
    hasContractLevel(levels) {
      return levels.some((level) => this.contractAgentLevels[level]);
    },

    formatOption(option) {
      const categoryLevels = [
        "med",
        "dent",
        "vision",
        "term_life",
        "bundled",
        "lim_med",
        "accident",
        "critical",
        "hospital",
        "life_style",
        "pet",
        "guarn_issue",
        "tele_med",
      ];
      const premierLevels = [
        "medL7",
        "dentL7",
        "visionL7",
        "term_lifeL7",
        "bundledL7",
        "lim_medL7",
        "accidentL7",
        "criticalL7",
        "hospitalL7",
        "life_styleL7",
        "petL7",
        "guarn_issueL7",
        "tele_medL7",
        "dentSolsticeL7",
        "visionSolsticeL7",
      ];

      if (
        option === "categoryContract" && this.hasContractLevel(categoryLevels)
      ) {
        return "Category Patriot-Ancillary Contract";
      }
      if (
        option === "premierContract"  &&
        this.hasContractLevel(premierLevels)
      ) {
        return "Category Smart-Ancillary Contract";
      }
      return "Non-Category Contract";
    },

    shouldShowOption(option) {
      const categoryLevels = [
        "med",
        "dent",
        "vision",
        "term_life",
        "bundled",
        "lim_med",
        "accident",
        "critical",
        "hospital",
        "life_style",
        "pet",
        "guarn_issue",
        "tele_med",
      ];
      const premierLevels = [
        "medL7",
        "dentL7",
        "visionL7",
        "term_lifeL7",
        "bundledL7",
        "lim_medL7",
        "accidentL7",
        "criticalL7",
        "hospitalL7",
        "life_styleL7",
        "petL7",
        "guarn_issueL7",
        "tele_medL7",
        "dentSolsticeL7",
        "visionSolsticeL7",
      ];

      if (
        option === "categoryContract"  && 
        this.hasContractLevel(categoryLevels)
      ) {
        return true;
      }
      if (
        option === "premierContract"  && 
        this.hasContractLevel(premierLevels)
      ) {
        return true;
      }
      if (option === "nonCategoryContract") {
        return true;
      }
      return false;
    },
    toggleChange(value) {
      this.isCategory = value;
      this.contractLevels = {};
      this.contractAgentLevels = {};
      this.getAgentContractLevels();
    },
    validateAndSendContractData() {
      return this.$refs.validationObserver.validate().then((valid) => {
        if (valid) {
          let paramData = this.getParams();
          if (
            this.selectedMainOption === "nonCategoryContract" &&
            this.checkAllValues(paramData.data)
          ) {
            this.$notify("At least one contract should be selected", "error");
            return null;
          }
          return paramData;
        } else {
          this.$notify("Please fill all required fields correctly.", "error");
          return null;
        }
      });
    },
    checkAllValues(obj) {
      const values = Object.values(obj);
      const allZero = values.every((value) => value == 0);
      if (allZero) {
        return true;
      }
    },

    getParams() {
      const data = {};
      let contractType = "";

      const nonCategoryContractData = {};

      if (this.selectedMainOption == "nonCategoryContract") {
        const agentLevelAlc = this.getAgentLevel("checkedLevelAlc");
        const agentLevelPec = this.getAgentLevel("checkedLevelPec");
        const agentLevelPecBex = this.getAgentLevel("checkedLevelPBex");
        const agentLevelAlcBex = this.getAgentLevel("checkedLevelABex");
        const agentLevelFegliAncillary = this.getAgentLevel(
          "checkedLevelFAnci"
        );
        const agentLevelPatriotAncillary = this.getAgentLevel(
          "checkedLevelPAnci"
        );

        data["agent_level_alc"] = agentLevelAlc;
        nonCategoryContractData["agent_level_alc"] = agentLevelAlc;

        data["agent_level_pec"] = agentLevelPec;
        nonCategoryContractData["agent_level_pec"] = agentLevelPec;

        data["agent_level_pec_bex"] = agentLevelPecBex;
        nonCategoryContractData["agent_level_pec_bex"] = agentLevelPecBex;

        data["agent_level_alc_bex"] = agentLevelAlcBex;
        nonCategoryContractData["agent_level_alc_bex"] = agentLevelAlcBex;

        data["agent_level_fegli_premier"] = 0;
        nonCategoryContractData["agent_level_fegli_premier"] = 0;

        data["agent_level_fegli_ancillary"] = agentLevelFegliAncillary;
        nonCategoryContractData[
          "agent_level_fegli_ancillary"
        ] = agentLevelFegliAncillary;

        data["agent_level_patriot_premier"] = 0;
        nonCategoryContractData["agent_level_patriot_premier"] = 0;

        data["agent_level_patriot_ancillary"] = agentLevelPatriotAncillary;
        nonCategoryContractData[
          "agent_level_patriot_ancillary"
        ] = agentLevelPatriotAncillary;

        contractType = "N-CatContrs";
      }

      if (this.selectedMainOption == "categoryContract") {
        data["agent_level_med"] = this.getAgentLevel("checkedLevelMedical");
        data["agent_level_dent"] = this.getAgentLevel("checkedLevelDental");
        data["agent_level_vision"] = this.getAgentLevel("checkedLevelVision");
        data["agent_level_term_life"] = this.getAgentLevel("checkedLevelTLife");
        data["agent_level_bundled"] = this.getAgentLevel("checkedLevelBundles");
        data["agent_level_lim_med"] = this.getAgentLevel(
          "checkedLevelLMedical"
        );
        data["agent_level_accident"] = this.getAgentLevel(
          "checkedLevelAccident"
        );
        data["agent_level_critical"] = this.getAgentLevel(
          "checkedLevelCritical"
        );
        data["agent_level_hospital"] = this.getAgentLevel(
          "checkedLevelHospital"
        );
        data["agent_level_life_style"] = this.getAgentLevel(
          "checkedLevelLStyle"
        );
        data["agent_level_pet"] = this.getAgentLevel("checkedLevelPet");
        data["agent_level_guarn_issue"] = this.getAgentLevel(
          "checkedLevelGIssue"
        );
        data["agent_level_tele_med"] = this.getAgentLevel(
          "checkedLevelTMedicine"
        );

        contractType = "catContrs";
      }

      if (this.selectedMainOption == "premierContract") {
        data["agent_level_medL7"] = this.getAgentLevel("checkedLevelMedicalL7");
        data["agent_level_dentL7"] = this.getAgentLevel("checkedLevelDentalL7");
        data["agent_level_visionL7"] = this.getAgentLevel(
          "checkedLevelVisionL7"
        );
        data["agent_level_term_lifeL7"] = this.getAgentLevel(
          "checkedLevelTLifeL7"
        );
        data["agent_level_bundledL7"] = this.getAgentLevel(
          "checkedLevelBundlesL7"
        );
        data["agent_level_lim_medL7"] = this.getAgentLevel(
          "checkedLevelLMedicalL7"
        );
        data["agent_level_accidentL7"] = this.getAgentLevel(
          "checkedLevelAccidentL7"
        );
        data["agent_level_criticalL7"] = this.getAgentLevel(
          "checkedLevelCriticalL7"
        );
        data["agent_level_hospitalL7"] = this.getAgentLevel(
          "checkedLevelHospitalL7"
        );
        data["agent_level_life_styleL7"] = this.getAgentLevel(
          "checkedLevelLStyleL7"
        );
        data["agent_level_petL7"] = this.getAgentLevel("checkedLevelPetL7");
        data["agent_level_guarn_issueL7"] = this.getAgentLevel(
          "checkedLevelGIssueL7"
        );
        data["agent_level_tele_medL7"] = this.getAgentLevel(
          "checkedLevelTMedicineL7"
        );
        data["agent_level_dentSolsticeL7"] = this.getAgentLevel(
          "checkedLevelSolsticeDental7"
        );
        data["agent_level_visionSolsticeL7"] = this.getAgentLevel(
          "checkedLevelSolsticeVision7"
        );

        contractType = "catContrsL7";
      }

      if (
        Object.keys(nonCategoryContractData).length > 0 &&
        this.checkAllValues(nonCategoryContractData)
      ) {
        this.$notify("At least one contract should be selected here", "error");
        return null;
      }
      let param = {};
      param.contract_type = contractType;
      param.data = data;
      param.action_origin = action_origin;
      param.repid = this.$store.getters.rep.id;
      return param;
    },

    sendContractData(param) {
      let url = `v2/registration/contract-info`;
      let loader = this.$loading.show();

      axios
        .post(url, param, {
          headers: {
            Authorization: "Basic " + process.env.VUE_APP_QA_BS_API_AUTH_KEY,
          },
        })
        .then((res) => {
          this.contractId = res.data.data.contract_id;
          const route = this.$router.resolve({
            name: "Registration",
            query: {
              repCode: this.rep.code,
              contract: res.data.data.contract_id,
            },
          });
          window.open(route.href, "_blank");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    redirectToRegistrationPage() {
      const route = this.$router.resolve({
        name: "Registration",
        query: { repCode: this.rep.code, contract: "Hello" },
      });
      window.open(route.href, "_blank");
    },
    getAgentLevel(checkedLevelKey) {
      return this.contractLevels[checkedLevelKey] || 0;
    },
    getAgentContractLevels() {
      /*eslint-disable*/
      let url = `/api/v2/get-contract-levels-new/${this.$store.getters.rep.id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.contractAgentLevels = res.data.data;
        })
        .catch((err) => {
          this.$notify(err.response.message, "error");
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleLevelSelected(levelData) {
      this.$set(this.contractLevels, levelData.name, levelData.level);
    },
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
    filteredOptions() {
      return this.optionArrays.filter((option) =>
        this.shouldShowOption(option)
      );
    },
  },
};
</script>
<template>
  <!-- Modal -->
  <b-modal
    id="sendEmailRegistrationLink"
    modal-class="rep-group-details-modal"
    @shown="onModalShown"
    centered
    size="lg"
    hide-header
    hide-footer
  >
    <div class="view-rep-header">
      <b-button variant="close-btn" @click="closeModal">
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <b-container fluid>
      <div class="view-rep-modal-contents mt-3">
        <h5><strong>Send Registration Link To User</strong></h5>
        <div class="addMessage-form mt-3">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(clickHandler)"
              ref="sendEmailRegistrationLink"
            >
              <div class="form-group row">
                <input-text
                  :rules="{ required: true, neverbounce: true }"
                  labelFor="email"
                  labelName="Email"
                  inputName="email"
                  :vmodel.sync="form.email"
                  formGroupClass="col-xxl-6 mb-3"
                  inputType="email"
                />

                <input-text
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="form.subject"
                  formGroupClass="col-xxl-6 mb-3"
                />

                <div class="col-xxl-12 mb-3">
                  <validation-provider name="Message" v-slot="{ errors }">
                    <label> Message </label>
                    <ckeditor
                      :editor="editor"
                      v-model="form.message"
                      :config="editorConfig"
                    />
                    <div class="invalid-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <select-contract-registration
                  ref="selectContractRegistration"
                />
              </div>
              <div class="row justify-content-md-center">
                <div class="col-xxl-5 mb-4">
                  <b-button class="univ-btn" type="submit" :disabled="isSend">
                    <template v-if="isSend"> Sending ... </template>
                    <template v-else> Send </template>
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectContractRegistration from "../SelectContractRegistration.vue";
export default {
  name: "SendRegistrationLinkEmailModal",
  components: {
    SelectContractRegistration,
  },
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    form: {
      email: "",
      message: "",
      subject: "",
      is_group_link: 0,
    },
    isSend: false,
  }),
  methods: {
    reset() {
      this.form = {
        email: "",
        message: "",
        subject: "",
        is_group_link: 0,
      };
      this.$refs.observer.reset();
    },
    onModalShown() {
      this.$refs.selectContractRegistration.getAgentContractLevels();
    },
    closeModal() {
      this.reset();
      this.$emit("onCloseModal");
      this.$bvModal.hide("sendEmailRegistrationLink");
    },
    clickHandler() {
      this.$refs.selectContractRegistration
        .validateAndSendContractData()
        .then((paramData) => {
          if (paramData) {
            console.log(paramData);
            this.sendEmail(paramData)
            this.$bvModal.hide("sendEmailRegistrationLink");
          }
        });
    },
    sendEmail(paramData) {
      console.log(paramData);
      console.log(this.form)
     let  params={
      ...paramData,
      mailData:{...this.form}
     }
     console.log(params)
      const url = `v2/send-contract-registration-info`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendEmailRegistrationLink,
      });
      Api.post(url, params)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
  },
};
</script>

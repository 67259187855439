var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-rep-modal-contents mt-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "addMessage-form mt-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedMainOption,
      expression: "selectedMainOption"
    }],
    staticClass: "select-input mb-3",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedMainOption = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.toggleChange(_vm.selectedMainOption);
      }]
    }
  }, _vm._l(_vm.filteredOptions, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "value": option
      }
    }, [_vm._v(_vm._s(_vm.formatOption(option)))]);
  }), 0)]), _vm.selectedMainOption === 'nonCategoryContract' ? _c('div', {
    staticClass: "row"
  }, [_c('SelectInput', {
    attrs: {
      "name": "checkedLevelABex",
      "count": _vm.contractAgentLevels.Abex - 1 < 0 ? 0 : _vm.contractAgentLevels.Abex - 1,
      "title": "BEX Ancillary Contract",
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelPBex",
      "title": "BEX Premier Contract",
      "count": _vm.contractAgentLevels.Pbex - 1 < 0 ? 0 : _vm.contractAgentLevels.Pbex - 1,
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "title": "Smart - Ancilliary Contract",
      "name": "checkedLevelAlc",
      "count": _vm.contractAgentLevels.alc,
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelPec",
      "count": _vm.contractAgentLevels.pec,
      "title": "EWA Contract (Union Only)",
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelFAnci",
      "count": _vm.contractAgentLevels.fegliAncillary,
      "title": "GPBP (FEGLI) Contract",
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelPAnci",
      "count": _vm.checkForPatriotAncillaryLevel(_vm.contractAgentLevels.patriotAncillary, _vm.contractAgentLevels.patriotPremier),
      "title": "Patriot - Ancillary Contract",
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    staticStyle: {
      "visibility": "hidden"
    },
    attrs: {
      "name": "checkedLevelPPre",
      "count": _vm.contractAgentLevels.patriotPremier,
      "title": "Patriot - Ancillary Contract",
      "validateInput": false
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  })], 1) : _vm._e(), _c('ValidationObserver', {
    ref: "validationObserver"
  }, [_vm.selectedMainOption === 'categoryContract' ? _c('div', {
    staticClass: "row"
  }, [_c('SelectInput', {
    attrs: {
      "name": "checkedLevelMedical",
      "count": _vm.contractAgentLevels.med,
      "title": "Medical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelDental",
      "count": _vm.contractAgentLevels.dent,
      "title": "Dental"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelVision",
      "count": _vm.contractAgentLevels.vision,
      "title": "Vision"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelTLife",
      "count": _vm.contractAgentLevels.term_life,
      "title": "Term Life"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelBundles",
      "count": _vm.contractAgentLevels.bundled,
      "title": "Bundles"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelLMedical",
      "count": _vm.contractAgentLevels.lim_med,
      "title": "Limited Medical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelAccident",
      "count": _vm.contractAgentLevels.accident,
      "title": "Accident"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelCritical",
      "count": _vm.contractAgentLevels.critical,
      "title": "Critical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelHospital",
      "count": _vm.contractAgentLevels.hospital,
      "title": "Hospital"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelLStyle",
      "count": _vm.contractAgentLevels.life_style,
      "title": "Life Style"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelPet",
      "count": _vm.contractAgentLevels.pet,
      "title": "Pet"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelGIssue",
      "count": _vm.contractAgentLevels.guarn_issue,
      "title": "Guranteed Issue"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelTMedicine",
      "count": _vm.contractAgentLevels.tele_med,
      "title": "Telemedicine"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  })], 1) : _vm._e(), _vm.selectedMainOption === 'premierContract' ? _c('div', {
    staticClass: "row"
  }, [_c('SelectInput', {
    attrs: {
      "name": "checkedLevelMedicalL7",
      "count": _vm.contractAgentLevels.medL7,
      "title": "Medical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelDentalL7",
      "count": _vm.contractAgentLevels.dentL7,
      "title": "Dental"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelVisionL7",
      "count": _vm.contractAgentLevels.visionL7,
      "title": "Vision"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelTLifeL7",
      "count": _vm.contractAgentLevels.term_lifeL7,
      "title": "Term Life"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelBundlesL7",
      "count": _vm.contractAgentLevels.bundledL7,
      "title": "Bundles"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelLMedicalL7",
      "count": _vm.contractAgentLevels.lim_medL7,
      "title": "Limited Medical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelAccidentL7",
      "count": _vm.contractAgentLevels.accidentL7,
      "title": "Accident"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelCriticalL7",
      "count": _vm.contractAgentLevels.criticalL7,
      "title": "Critical"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelHospitalL7",
      "count": _vm.contractAgentLevels.hospitalL7,
      "title": "Hospital"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelLStyleL7",
      "count": _vm.contractAgentLevels.life_styleL7,
      "title": "Life Style"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelPetL7",
      "count": _vm.contractAgentLevels.petL7,
      "title": "Pet"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelGIssueL7",
      "count": _vm.contractAgentLevels.guarn_issueL7,
      "title": "Guaranteed Issue"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelTMedicineL7",
      "count": _vm.contractAgentLevels.tele_medL7,
      "title": "Telemedicine"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelSolsticeDental7",
      "count": _vm.contractAgentLevels.dentSolsticeL7,
      "title": "Dental-Solstice"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  }), _c('SelectInput', {
    attrs: {
      "name": "checkedLevelSolsticeVision7",
      "count": _vm.contractAgentLevels.visionSolsticeL7,
      "title": "Vision-Solstice"
    },
    on: {
      "level-selected": _vm.handleLevelSelected
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', [_c('strong', [_vm._v("Send Representative Contract")])]);
}]

export { render, staticRenderFns }
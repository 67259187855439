<template>
  <div class="page-wrapper downlinerep-wrapper" ref="downlineRepContainer">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>
          Downline Reps
          <a href="javascript:void(0)" @click.prevent="addNewDownline">
            Add New
          </a>
          <a
            href="javascript:void(0)"
            @click.prevent="
              openRegistrationLinkModel('sendEmailRegistrationLink')
            "
            style="background: #3bb54a !important"
            class="registration-link"
          >
            Send Registration Link
          </a>
        </h1>
        <button class="clear-search" @click="clearDownline">
          Clear Search
        </button>
      </div>
      <downline-rep-list-filter :filters="filterQuery" @onSearch="filterData" />
      <div class="downlinedata-card">
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <page-result :rows.sync="rows" />
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </div>
        <downline-rep-list-table
          :rows.sync="rows"
          @onSuccess="getAll(filterQuery)"
        />
        <send-registration-link-email-modal />
        <downline-registration-contract-modal
          @redirectToRegistrationPage="redirectToRegistrationPage"
        />
        <pagination :rows.sync="rows" :filters="filterQuery" />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import DownlineRepListTable from "./partials/DownlineRepListTable";
import DownlineRepListFilter from "./partials/DownlineRepListFilter";
import sendRegistrationLinkEmailModal from "./partials/SendRegistrationLinkEmailRepModal";
import DownlineRegistrationContractModal from "./DownlineRegistrationContractModal.vue";

export default {
  name: "DowlineRepList",
  components: {
    DownlineRepListTable,
    DownlineRepListFilter,
    sendRegistrationLinkEmailModal,
    DownlineRegistrationContractModal,
  },
  data: () => ({
    rows: [],
    filterQuery: {
      agent_fname: "",
      agent_lname: "",
      agent_code: "",
      agent_state: "",
      agent_phone: "",
      agent_email: "",
      upline_code: "",
      upline_name: "",
      group_id: "",
      agent_status: "",
    },
    breadcrumbItems: [{ title: "Dowline Reps", active: true }],
  }),
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    addNewDownline() {
      this.openRegistrationLinkModel("downlineContract");
      // this.redirectToRegistrationPage()
      // const route = this.$router.resolve({
      //   name: "Registration",
      //   query: { repCode: this.rep.code },
      // });
      // window.open(route.href, "_blank");
    },
    redirectToRegistrationPage() {
      const route = this.$router.resolve({
        name: "Registration",
        query: { repCode: this.rep.code },
      });
      window.open(route.href, "_blank");
    },
    openRegistrationLinkModel(modelId) {
      this.$bvModal.show(modelId);
    },
    clearDownline() {
      this.filterQuery = {
        agent_fname: "",
        agent_lname: "",
        agent_code: "",
        agent_state: "",
        agent_phone: "",
        agent_email: "",
        upline_code: "",
        upline_name: "",
        group_id: "",
        agent_status: "",
      };
      this.getAll();
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getAll(this.filterQuery);
    },
    getAll(filters = []) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/get-all-active-downline-reps?${filterUrl}`;
      } else {
        url = `v2/get-all-active-downline-reps`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    if (this.$route.params.agent_status) {
      this.filterQuery.agent_status = this.$route.params.agent_status;
      this.getAll(this.filterQuery);
    } else {
      this.getAll();
    }
  },
};
</script>
<style src="@/assets/css/downline-reps.css"></style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "sendEmailRegistrationLink",
      "modal-class": "rep-group-details-modal",
      "centered": "",
      "size": "lg",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.onModalShown
    }
  }, [_c('div', {
    staticClass: "view-rep-header"
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })])], 1), _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-rep-modal-contents mt-3"
  }, [_c('h5', [_c('strong', [_vm._v("Send Registration Link To User")])]), _c('div', {
    staticClass: "addMessage-form mt-3"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "sendEmailRegistrationLink",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.clickHandler);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              neverbounce: true
            },
            "labelFor": "email",
            "labelName": "Email",
            "inputName": "email",
            "vmodel": _vm.form.email,
            "formGroupClass": "col-xxl-6 mb-3",
            "inputType": "email"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "subject",
            "labelName": "Subject",
            "inputName": "subject",
            "vmodel": _vm.form.subject,
            "formGroupClass": "col-xxl-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        }), _c('div', {
          staticClass: "col-xxl-12 mb-3"
        }, [_c('validation-provider', {
          attrs: {
            "name": "Message"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', [_vm._v(" Message ")]), _c('ckeditor', {
                attrs: {
                  "editor": _vm.editor,
                  "config": _vm.editorConfig
                },
                model: {
                  value: _vm.form.message,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "message", $$v);
                  },
                  expression: "form.message"
                }
              }), _c('div', {
                staticClass: "invalid-block invalid-feedback"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('select-contract-registration', {
          ref: "selectContractRegistration"
        })], 1), _c('div', {
          staticClass: "row justify-content-md-center"
        }, [_c('div', {
          staticClass: "col-xxl-5 mb-4"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm.isSend ? [_vm._v(" Sending ... ")] : [_vm._v(" Send ")]], 2)], 1)])])];
      }
    }])
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
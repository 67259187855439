<template>
  <!--New Send Rep contract Modal -->
  <div class="send-info">
    <b-modal
      id="downlineContract"
      @shown="onModalShown"
      centered
      size="lg"
      hide-footer
      hide-header
    >
      <div class="view-rep-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
      <b-container fluid>
        <Select-contract-registration ref="selectContractRegistration" />
      </b-container>
      <div class="contract-btn-container">
        <b-button
          class="univ-btn contract-btn"
          variant="primary"
          @click="clickHandler"
          data-dismiss="modal"
        >
          Assign
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SelectContractRegistration from "./SelectContractRegistration.vue";
export default {
  name: "DownlineRegistrationContractModal",
  components: {
    SelectContractRegistration,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("downlineContract");
    },
    clickHandler() {
      this.$refs.selectContractRegistration.validateAndSendContractData().then((paramData) => {
        if (paramData) {
        console.log(paramData)
          this.$refs.selectContractRegistration
      .sendContractData(paramData)
      this.$bvModal.hide("downlineContract");
      
        }
        
      })
    },
    onModalShown() {
      this.$refs.selectContractRegistration.getAgentContractLevels();
    },
  },
};
</script>
<style>
.select-input {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  min-width: 100%;
  font-size: 14px;
}

.select-input:focus-visible {
  outline: none;
}

.send-label {
  font-weight: 700;
  color: #000;
  font-size: 14px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
}

.table-cell {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.header .table-cell {
  font-weight: 700;
}

.visited {
  border-color: #29bcff;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.header {
  background-color: #f2f2f2;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: flex;
}

.contract-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.contract-btn {
  width: 20% !important;
  margin-right: 1.5%;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "send-info"
  }, [_c('b-modal', {
    attrs: {
      "id": "downlineContract",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "hide-header": ""
    },
    on: {
      "shown": _vm.onModalShown
    }
  }, [_c('div', {
    staticClass: "view-rep-header"
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })])], 1), _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('Select-contract-registration', {
    ref: "selectContractRegistration"
  })], 1), _c('div', {
    staticClass: "contract-btn-container"
  }, [_c('b-button', {
    staticClass: "univ-btn contract-btn",
    attrs: {
      "variant": "primary",
      "data-dismiss": "modal"
    },
    on: {
      "click": _vm.clickHandler
    }
  }, [_vm._v(" Assign ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }